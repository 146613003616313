<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">学员统计(按班统计)</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl flexdc operationStudent"
          style="align-items: flex-start"
        >
          <div class="searchbox">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">学员搜索:</span>
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 100px;
                      "
                      >学员姓名</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 140px;
                      "
                      >身份证号码</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 100px;
                      "
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item,index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span class="itemLabel">班级搜索:</span>
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superPorjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superPorjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item,index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
             <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="(item,index) in CompanyList"
                  :key="index"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top: 10px">
            <div title="所属企业" class="searchboxItem ci-full">
              <span class="itemLabel">所属企业:</span>
              <el-select
                size="small"
                v-model="userCompId"
                remote
                :remote-method="getuserCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
                style="width: 100%"
              >
                <el-option
                  v-for="(item,index) in userCompanyList"
                  :key="index"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="是否结业" class="searchboxItem ci-full">
              <span class="itemLabel">是否结业:</span>
              <el-select
                v-model="graduationState"
                placeholder="请选择"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="(item,index) in graduationStateList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
               <div title="入班时间" class="searchboxItem ci-full">
              <span class="itemLabel">入班时间:</span>
              <el-date-picker
                v-model="date"
                size="small"
                type="daterange"
                style="width: 5rem"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="timestamp"
              />
            </div>
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="exportData()"
              >导出</el-button
            >
          </div>
        </div>
        <div style="margin: 5px 15px 0px">
          <span>培训要求总学时：{{ requireLessonSum }}</span>
          <span style="margin: 0 15px"
            >培训完成总学时：{{ studyLessonSum }}</span
          >
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                fixed
                width="160"
              />
              <el-table-column
                label="联系电话"
                width="100"
                align="left"
                show-overflow-tooltip
                prop="mobile"
              />
              <el-table-column
                label="机构名称"
                width="200"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                width="200"
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                width="200"
              />
              <el-table-column
                label="课件总数"
                align="right"
                show-overflow-tooltip
                prop="kpointTotalNum"
              />
              <el-table-column
                label="已完成课件数"
                align="right"
                show-overflow-tooltip
                prop="completeKpointNum"
              />
              <el-table-column
                label="结业状态"
                align="right"
                show-overflow-tooltip
                width="80px"
                prop="graduationStateStr"
              ></el-table-column>
              <el-table-column
                label="累计在线学习时长"
                align="right"
                show-overflow-tooltip
                prop="totalTime"
                width="150"
              >
               <template slot-scope="scope">
                {{getTime(Number(scope.row.totalTime))}}
              </template>
              </el-table-column>
              <el-table-column
                label="所属企业"
                align="left"
                show-overflow-tooltip
                prop="userCompName"
                width="200"
              />
              <el-table-column
                label="入班时间"
                align="left"
                show-overflow-tooltip
                width="150px"
              >
                <template slot-scope="scope">{{
                  scope.row.createTime | moment
                }}</template>
              </el-table-column>
            
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="260px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      handleCourseDetail(scope.row.userId, scope.row.projectId)
                    "
                    >课程详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="
                      handledetail(scope.row.userId, scope.row.projectId)
                    "
                    >学习详情</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleTrajectory(scope.row.userId, scope.row.projectId)"
                    >学习轨迹</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { Message } from "element-ui";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/studentStatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      exportName: "projectName", // 导出名称
      compId: "",
      userCompId: "",
      CompanyList: [],
      userCompanyList: [],
      requireLessonSum: 0,
      studyLessonSum: 0,
      completeState: "",
      complete: [],
    graduationState:'',
      graduationStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: 1,
          label: "是",
        },
        {
          value: 0,
          label: "否",
        },
      ],
      date: [],
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
    };
  },
  //页面初始化时不自动查询列表数据
  doNotInit: true,
  created() {
    this.getTableHeight();
    this.getcompleteList();
    this.superUserSelect();
    this.superPorjectSelect();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superPorjectSelect();
    },
  },
  methods: {
    superUserSelect(e) {
      this.$post(
        "/biz/user/superUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    superPorjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    getSearchParams(){
      let params = {
        projectId: this.projectId || "",
        userId: this.userId || "",
        compNameId: this.compId || "",
        userCompId: this.userCompId || "",
        graduationState:this. graduationState
      };
      if (this.date) {
        params.createTimeBegin = this.date[0];
        params.createTimeEnd = this.date[1];
      }
      return params;
    },
    clearProjectSearchModel(e){
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superPorjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    exportData() {
      if (this.projectId == "") {
        Message.error({
          message: "请先选择班级",
          duration: "1000",
        });
        return;
      }
      this.$post("/biz/report/user/page-RB-export", this.getSearchParams()).then(res => {
        // if (res.status == 0) {
        //   window.open(res.data);
        // } else {
        //   this.$message({
        //     type: "error",
        //     message: res.message
        //   });
        // }
        if (res.status == "0") {
          let list = res.data;
          this.editPopupDialogVisible = false;
          if (!this.downloadItems.includes(list.taskId)) {
            this.$store.dispatch("pushDownloadItems", list.taskId);
          } else {
            this.$message.warning(
              "[" + list.fileName + "]已经申请下载,请耐心等待"
            );
          }
        } else {
          this.$message.error(res.message);
        }
      }).catch(() => {
        return;
      });
    },
    getcompleteList() {
      const studylist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in studylist) {
        list.push({
          value: key,
          label: studylist[key],
        });
      }
      this.complete = list;
    },

    getData(pageNum = 1) {
      if (this.projectId == "" && this.userId == "" && this.compId == "") {
        Message.error({
          message: "班级、学员、机构必须选择一项",
          duration: "2000",
        });
        return;
      }
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      let par = this.getSearchParams();
      params = {
        ...params,
        ...par,
      };
      this.doFetch({
        url: "/biz/report/user/page-RB-list",
        params,
        pageNum,
      });
      this.getListInfo({
        url: "/biz/report/user/userRBTotal",
        params: par,
      });
    },
    getListInfo(params) {
      this.$post(params.url, params.params).then((res) => {
        if (res.status == "0") {
          const data = res.data || {};
          this.requireLessonSum = data.requireLessonNumTotal;
          this.studyLessonSum = data.studyLessonNumTotal;
        }
      });
    },
    /* 学习轨迹 */
    handleTrajectory(userId, projectId) {
      this.$router.push({
        path: "/web/operate/TrajectoryListbyClass",
       query: { userId, projectId },
      });
    },
    // 资源提供者
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /* 所属企业 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.userCompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.userCompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    getTime(val) {
      if (!val) {
        return "00时00分00秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return h + "时" + m + "分" + s + "秒" || "00时00分00秒";
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    handledetail(userId, projectId) {
      this.$router.push({
        path: "/web/operate/learningDetailsByClass",
        query: { userId, projectId },
      });
    },
    //课程详情
    handleCourseDetail(userId, projectId) {
      this.$router.push({
        path: "/web/operate/courseListByClass",
        query: { userId, projectId },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.operationStudent {
  .itemLabel {
    min-width: 5rem;
  }
  .ci-full {
    .el-input--suffix .el-input__inner {
      padding-right: 3px;
    }
    .el-input {
      input {
        width: 100% !important;
      }
    }
    .smallselect {
      input {
        width: 100% !important;
      }
    }
  }
}
.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
</style>
